.app{
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.form{
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}
